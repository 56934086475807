import React from "react";
import styled from "styled-components";
// Assets
import ContactImg1 from "../../assets/img/Asset6.svg";
// components
import Form from "../Elements/Form";

export default function Contact() {
  return (
    <Wrapper id="contact">
      <div className="greyBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Let's get in touch</h1>
            <p className="font13">
              Please submit your request, 
              <br/>
              and one of our specialists will promptly reach out to you.
            </p>
          </HeaderInfo>
          <ContentRow>
            <FormWrapper>
              <Form />
            </FormWrapper>
            <ImageWrapper>
              <img src={ContactImg1} alt="office" className="radius6" />
            </ImageWrapper>
          </ContentRow>
        </div>
      </div>
    </Wrapper>
  );
}

// Styled Components
const Wrapper = styled.section`
  width: 100%;
`;

const HeaderInfo = styled.div`
  padding: 70px 0 30px 0;
  @media (max-width: 860px) {
    text-align: center;
  }
`;

const ContentRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 30px;

  @media (max-width: 860px) {
    flex-direction: column;
  }
`;

const FormWrapper = styled.div`
  flex: 1;
  padding-right: 20px;

  @media (max-width: 860px) {
    padding-right: 0;
    width: 100%;
    margin-bottom: 20px;
  }
`;

const ImageWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
    max-width: 500px;
    height: auto;
    object-fit: cover;
    border-radius: 6px;
  }
`;

