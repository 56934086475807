import React from "react";
import styled from "styled-components";
// import { scroller } from "react-scroll";
import { useNavigate } from "react-router-dom";
// Assets
import AddImage1 from "../../assets/img/Asset2.svg";
// components 
import ClientStats from "../Elements/ClientStats";
import ServiceBox from "../Elements/ServiceBox";
import FullButton from "../Buttons/FullButton";

export default function Services() {
  const navigate = useNavigate(); // Hook for navigation

  const goTogetstarted = () => {
    navigate("/getstarted"); // Navigate to the new page
  };

  // const scrollToContact = () => {
  //   scroller.scrollTo("contact", {
  //     duration: 800,
  //     delay: 0,
  //     smooth: "easeInOutQuart",
  //   });
  // };

  return (
    <Wrapper id="services">
      <div className="greyBg" style={{ padding: "50px 0" }}>
        <div className="container">
          <ClientStats />
        </div>
      </div>
      <div className="whiteBg" style={{ padding: "60px 0" }}>
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Our Awesome Services</h1>
            <p className="font13">
            Experience excellence with our top-notch services—crafted to bring your vision to life with precision, innovation, and dedication
              <br />
            </p>
          </HeaderInfo>
          <ServiceBoxGrid>
            <ServiceBoxWrapper>
              <ServiceBox
                icon="ML"
                title="Machine Learning"
                subtitle="Developing predictive models and intelligent algorithms to drive insights and decision-making."
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox
                icon="WD"
                title="Web Design"
                subtitle="Creating engaging and responsive websites that provide an optimal user experience."
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox
                icon="Dev"
                title="Development"
                subtitle="Building high-performance, scalable applications tailored to business needs."
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox 
                icon="DS" 
                title="Data Science" 
                subtitle="Turning complex data into actionable insights for data-driven strategies."
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox 
                icon="DataBase" 
                title="Database Design" 
                subtitle="Structuring and optimizing databases for efficient data storage and retrieval."
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox 
                icon="GD" 
                title="Game Development" 
                subtitle="Creating immersive gaming experiences through innovative design and technology."
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox 
                icon="SaaS" 
                title="SaaS" 
                subtitle="Building scalable, secure, and user-friendly cloud-based applications."
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox 
                icon="MA" 
                title="Mobile Apps" 
                subtitle="Designing seamless mobile applications that enhance user engagement and accessibility."
              />
            </ServiceBoxWrapper>
          </ServiceBoxGrid>
        </div>
        <div className="greyBg">
          <div className="container">
            <Advertising className="flexSpaceCenter">
              <AddLeft>
                <h4 className="font15 semiBold">We are a</h4>
                <h2 className="font40 extraBold">Study of Creativity</h2>
                <p className="font12">
                Octopuda is about exploring creative solutions through innovative design and technology. We are dedicated to unlocking new possibilities for our clients, blending cutting-edge advancements with imaginative artistry.
                </p>
                <ButtonsRow className="flexNullCenter" style={{ margin: "30px 0"}}>
                  <div style={{ width: "190px" }}>
                    <FullButton title="Get Started" action={goTogetstarted} />
                  </div>
                  {/* <div style={{ width: "190px", marginLeft: "15px" }}>
                    <FullButton title="Contact Us" action={scrollToContact} border />
                  </div> */}
                </ButtonsRow>
              </AddLeft>
              <AddRight>
                <AddRightInner>
                  <div className="flexNullCenter">
                    <AddImgWrapp1 className="flexCenter">
                      <img src={AddImage1} alt="office" />
                    </AddImgWrapp1>
                  </div>
                </AddRightInner>
              </AddRight>
            </Advertising>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const ServiceBoxGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  padding: 20px;
`;
const ServiceBoxWrapper = styled.div`
  padding: 30px;

`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Advertising = styled.div`
  margin: 80px 0;
  padding: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 100px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;
const AddLeft = styled.div`
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  position: absolute;
  top: -70px;
  right: 0;
  @media (max-width: 860px) {
    width: 80%;
    position: relative;
    order: 1;
    top: -40px;
  }
`;
const AddRightInner = styled.div`
  width: 100%;
`;
const AddImgWrapp1 = styled.div`
  width: 108%;
  margin: 0 6% 10px 6%;
  img {
    width: 100%;
    height: auto;
  }
`;