import React from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
// Assets
import Logo from "../../assets/svg/FullLogo";

export default function Contact() {

  const getCurrentYear = () => {
    return new Date().getFullYear();
  }

  return (
    <Wrapper>
      <div className="darkBg">
        <div className="container">
          <InnerWrapper className="flexSpaceCenter" style={{ padding: "30px 0" }}>

            <Link to="home" smooth={true} offset={-80}>
              <LogoWrapper>
                <Logo />
              </LogoWrapper>
            </Link>
    
            <StyleP className="whiteColor font13">
              © {getCurrentYear()} - <span className="green3 font13">Octopuda</span> All Right Reserved
            </StyleP>

          </InnerWrapper>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
`;

const StyleP = styled.p`
  @media (max-width: 550px) {
    margin: 20px 0;
  }
`;

const LogoWrapper = styled.div`
  margin-top: 20px;
  text-align: center;
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column; 
  align-items: center; 
  justify-content: center;
  text-align: center; 
  
  @media (max-width: 550px) {
    flex-direction: column; 
  }
`;