import React, { useState } from "react";
import styled from "styled-components";

const Form = () => {
  const initialFormState = {
    name: "",
    email: "",
    message: ""
  };

  const [formData, setFormData] = useState(initialFormState);
  const [isLoading, setIsLoading] = useState(false);
  const [submitStatus, setSubmitStatus] = useState({
    type: "",
    message: ""
  });

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const validateForm = () => {
    if (!formData.name.trim()) {
      setSubmitStatus({
        type: "error",
        message: "Please enter your name"
      });
      return false;
    }
    if (!validateEmail(formData.email)) {
      setSubmitStatus({
        type: "error",
        message: "Please enter a valid email address"
      });
      return false;
    }
    if (!formData.message.trim()) {
      setSubmitStatus({
        type: "error",
        message: "Please enter your message"
      });
      return false;
    }
    return true;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    // Clear status messages when user starts typing
    if (submitStatus.message) {
      setSubmitStatus({ type: "", message: "" });
    }
  };

  const resetForm = () => {
    setFormData(initialFormState);
    setSubmitStatus({ type: "", message: "" });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!validateForm()) return;

    setIsLoading(true);
    setSubmitStatus({ type: "", message: "" });

    try {
      const response = await fetch("https://backend-for-octopuda.fly.dev/contactusForm", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setSubmitStatus({
          type: "success",
          message: "Thank you for your message! We'll get back to you soon."
        });
        resetForm();
      } else {
        throw new Error("Failed to submit form");
      }
    } catch (error) {
      setSubmitStatus({
        type: "error",
        message: "Failed to send message. Please try again later."
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <FormWrapper onSubmit={handleSubmit}>
      {submitStatus.message && (
        <StatusMessage type={submitStatus.type}>
          {submitStatus.message}
        </StatusMessage>
      )}

      <Label htmlFor="nameInput">Name</Label>
      <Input
        type="text"
        id="nameInput"
        name="name"
        placeholder="Enter your name"
        value={formData.name}
        onChange={handleChange}
        disabled={isLoading}
        required
      />

      <Label htmlFor="emailInput">Email</Label>
      <Input
        type="email"
        id="emailInput"
        name="email"
        placeholder="Enter your email"
        value={formData.email}
        onChange={handleChange}
        disabled={isLoading}
        required
      />

      <Label htmlFor="messageInput">Message</Label>
      <Textarea
        id="messageInput"
        name="message"
        placeholder="Write your message here"
        value={formData.message}
        onChange={handleChange}
        disabled={isLoading}
        required
      />

      <ButtonGroup>
        <Button 
          type="submit" 
          disabled={isLoading}
          isLoading={isLoading}
        >
          {isLoading ? "Sending..." : "Send"}
        </Button>
        <ResetButton
          type="button"
          onClick={resetForm}
          disabled={isLoading}
        >
          Reset
        </ResetButton>
      </ButtonGroup>
    </FormWrapper>
  );
};

export default Form;

const FormWrapper = styled.form`
  background-color: #f7f7f7;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const Label = styled.label`
  font-size: 14px;
  font-weight: bold;
  color: #333;
  margin-bottom: 5px;
`;

const Input = styled.input`
  padding: 10px 15px;
  font-size: 14px;
  border-radius: 5px;
  border: 1px solid #ccc;
  &:focus {
    outline: none;
    border-color: #007bff;
  }
  &:disabled {
    background-color: #f0f0f0;
    cursor: not-allowed;
  }
`;

const Textarea = styled.textarea`
  padding: 10px 15px;
  font-size: 14px;
  border-radius: 5px;
  border: 1px solid #ccc;
  resize: vertical;
  min-height: 120px;
  &:focus {
    outline: none;
    border-color: #007bff;
  }
  &:disabled {
    background-color: #f0f0f0;
    cursor: not-allowed;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 15px;
  margin-top: 10px;
`;

const Button = styled.button`
  flex: 2;
  padding: 12px 24px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  background: ${props => props.isLoading 
    ? '#cccccc'
    : 'linear-gradient(90deg, #019301, #49CB86)'};
  border: none;
  border-radius: 25px;
  cursor: ${props => props.isLoading ? 'not-allowed' : 'pointer'};
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;

  &:hover:not(:disabled) {
    transform: translateY(-3px);
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);
    background: linear-gradient(90deg, #49CB86, #019301);
  }

  &:active:not(:disabled) {
    transform: translateY(1px);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  }

  &:disabled {
    opacity: 0.7;
  }
`;

const ResetButton = styled(Button)`
  flex: 1;
  background: linear-gradient(90deg, #6c757d, #495057);

  &:hover:not(:disabled) {
    background: linear-gradient(90deg, #495057, #6c757d);
  }
`;

const StatusMessage = styled.div`
  padding: 12px;
  border-radius: 5px;
  text-align: center;
  background-color: ${props => props.type === 'success' ? '#d4edda' : '#f8d7da'};
  color: ${props => props.type === 'success' ? '#155724' : '#721c24'};
  border: 1px solid ${props => props.type === 'success' ? '#c3e6cb' : '#f5c6cb'};
  margin-bottom: 15px;
`;