import styled from "styled-components";
import CountUp from 'react-countup';
import React, { useState, useEffect } from "react";
import { useInView } from 'react-intersection-observer';

export default function ClientStats() {
  const { ref, inView } = useInView({
    threshold: 0.5,  // Start animation when 50% of the component is in view
  });
  
  const [shouldAnimate, setShouldAnimate] = useState(false);

  useEffect(() => {
    if (inView) {
      setShouldAnimate(true); // Trigger animation when in view
    } else {
      setShouldAnimate(false); // Reset animation when out of view
    }
  }, [inView]);

  return (
    <StatsContainer ref={ref}>
      <StatItem>
        <StatNumber>
          {shouldAnimate && <CountUp key={Math.random()} end={50} duration={3} />}+
        </StatNumber>
        <StatLabel>Clients</StatLabel>
      </StatItem>
      <StatItem>
        <StatNumber>
          {shouldAnimate && <CountUp key={Math.random()} end={120} duration={3} />}+
        </StatNumber>
        <StatLabel>Projects</StatLabel>
      </StatItem>
      <StatItem>
        <StatNumber>
          {shouldAnimate && <CountUp key={Math.random()} end={5000} duration={3} />}+
        </StatNumber>
        <StatLabel>Hours Worked</StatLabel>
      </StatItem>
    </StatsContainer>
  );
}

const StatsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 20px auto;
  padding: 20px;
  background-color: #f7f7f7;
  border-radius: 10px;
  max-width: 800px;
`;

const StatItem = styled.div`
  text-align: center;
  padding: 10px;
`;

const StatNumber = styled.h2`
  font-size: 2.5rem;
  color: #000;
  margin: 0;
`;

const StatLabel = styled.p`
  font-size: 1rem;
  color: #707070;
  margin: 5px 0 0 0;
`;
