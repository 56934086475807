import React from "react";
import styled from "styled-components";

import { FaBrain,FaDatabase,FaGamepad,FaMobileAlt,FaLaptop,FaCode } from "react-icons/fa";
import { BsClipboardDataFill } from "react-icons/bs";
import { DiCodeBadge } from "react-icons/di";

export default function ServiceBox({icon, title, subtitle}) {
  let getIcon;

  switch (icon) {
    case "ML":
      getIcon = <FaBrain color="green" size="2em" />;
      break;
    case "WD":
      getIcon = <FaLaptop color="green" size="2em" />;
      break;
    case "Dev":
      getIcon = <FaCode color="green" size="2em" />;
      break;
    case "DS":
      getIcon = <BsClipboardDataFill color="green" size="2em" />;
      break;
    case "DataBase":
      getIcon = <FaDatabase color="green" size="2em" />;
      break;
    case "GD":
      getIcon = <FaGamepad color="green" size="2em" />;
      break;
    case "SaaS":
      getIcon = <DiCodeBadge color="green" size="2em" />;
      break;
    case "MA":
      getIcon = <FaMobileAlt color="green" size="2em" />;
      break;
    default:
      getIcon = <FaBrain color="green" size="2em" />;
      break;
  }

  return (
    <Wrapper className="flex flexColumn">
      <IconStyle>{getIcon}</IconStyle>
      <TitleStyle className="font20 extraBold">{title}</TitleStyle>
      <SubtitleStyle className="font13">{subtitle}</SubtitleStyle>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
`;
const IconStyle = styled.div`
  @media (max-width: 860px) {
    margin: 0 auto;
  }
`;
const TitleStyle = styled.h2`
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
  padding: 40px 0;
  @media (max-width: 860px) {
    padding: 20px 0;
  }
`;
const SubtitleStyle = styled.p`
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
`;